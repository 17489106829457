/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tabs } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import PermissionWrapper from 'routes/PermissionWrapper';
import { CardWrapper } from 'components/CardWrapper';
import Wrapper from 'pages/private/wrapper';

import RestrictionList from './RestrictionList';
import DenyList from './DenyList';

const I18N_BASE_PATH = 'pages.private.restrictions.index';

const Restrictions = () => {
  const { t: translate } = useTranslation();

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });

  const activeKey = useMemo(() => {
    if (query?.tab === 'deny_list') {
      return 'deny_list';
    }

    return 'restrictive_list';
  }, [query]);

  return (
    <Wrapper>
      <CardWrapper hasPadding>
        <PermissionWrapper
          or
          requiredPermissions={[
            'restrictive-list:create',
            'restrictive-list:update',
            'restrictive-list:delete',
            'deny-list:create',
            'deny-list:update',
            'deny-list:delete',
          ]}
        >
          <Tabs
            defaultActiveKey={query?.tab || 'restrictive_list'}
            activeKey={activeKey}
            onChange={(tab) => setQuery({ tab }, 'replace')}
          >
            <Tabs.TabPane
              tab={translate(`${I18N_BASE_PATH}.restrictiveList`)}
              key="restrictive_list"
            >
              <PermissionWrapper
                or
                requiredPermissions={[
                  'restrictive-list:create',
                  'restrictive-list:update',
                  'restrictive-list:delete',
                ]}
              >
                <RestrictionList />
              </PermissionWrapper>
            </Tabs.TabPane>
            <Tabs.TabPane
              forceRender
              tab={translate(`${I18N_BASE_PATH}.denyList`)}
              key="deny_list"
            >
              <PermissionWrapper
                or
                requiredPermissions={[
                  'deny-list:create',
                  'deny-list:update',
                  'deny-list:delete',
                ]}
              >
                <DenyList />
              </PermissionWrapper>
            </Tabs.TabPane>
          </Tabs>
        </PermissionWrapper>
      </CardWrapper>
    </Wrapper>
  );
};

export default Restrictions;

import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';

import Login from 'pages/public/Login';

import Dashboard from 'pages/private/Dashboard';
import Home from 'pages/private/Home';

import NotificationCenter from 'pages/private/Notifications';

import Profiles from 'pages/private/Profiles';
import GlobalProfiles from 'pages/private/GlobalProfiles';
import CompanyDetails from 'pages/private/Profiles/Companies/CompanyDetails';
import PeopleDetails from 'pages/private/Profiles/People/PeopleDetails';
import GlobalPeopleDetails from 'pages/private/GlobalProfiles/People';

import Executions from 'pages/private/Executions';
import CompanySearching from 'pages/private/KYBCompany/CompanySearching';
import KYBCompanyDetails from 'pages/private/KYBCompany/CompanyDetailsV2';

import ExecutionDetails from 'pages/private/Executions/ExecutionDetails';
import Liveness from 'pages/private/Liveness';
import FaceAuthenticator from 'pages/private/FaceAuthenticator';
import TagsAndReasons from 'pages/private/TagsAndReasons';

import Restrictions from 'pages/private/Restrictions';
import BlockedDocuments from 'pages/private/BlockedDocuments/BlockedDocumentsList';
import Files from 'pages/private/Files';

import Unauthorized from 'pages/private/Unauthorized';

import OnboardingLinksList from 'pages/private/Links';
import OnboardingStatistics from 'pages/private/OnboardingStatistics';
import Activities from 'pages/private/Activities';

import ProfileModelsList from 'pages/private/ProfileModels/ProfileModels/components/ProfileModelList';
import Workflows from 'pages/private/ProfileModels/Workflows';
import ProfileModelUpdate from 'pages/private/ProfileModels/ProfileModelUpdate';
import ProfileModelCreate from 'pages/private/ProfileModels/ProfileModelCreate';

import RoutineDetails from 'pages/private/Routines/Detail';
import RoutineList from 'pages/private/Routines/List';

import UsersAndGroupsTab from 'pages/private/UsersAndGroupsTab';
import UsersPDV from 'pages/private/UsersPDVManagement';
import GroupsCreate from 'pages/private/GroupsManagement/GroupsCreate';
import GroupsDetails from 'pages/private/GroupsManagement/GroupsDetails';
import UsersCreate from 'pages/private/UserManagement/UsersCreate';
import pdvTenants from 'utils/skyPDVTenants';

import Consumptions from 'pages/private/Consumptions';
import ConsumptionDetails from 'pages/private/Consumptions/Details';
import Faces from 'pages/private/Faces';
import OnboardingBuilder from 'pages/private/OnboardingBuilder';
import RegistrationInfo from 'pages/private/Registration';
import AccessKeys from 'pages/private/AccessKeys';

import AuditLog from 'pages/private/SecurityAndPrivacy/AuditLog';
import SecurityConfiguration from 'pages/private/SecurityAndPrivacy/SecurityConfiguration';
import LivenessConfiguration from 'pages/private/SecurityAndPrivacy/LivenessConfiguration';
import Accounts from 'pages/private/Accounts';

import { useAuth } from 'hooks/auth';

import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import handleHubSelection from 'utils/handleHubSelection';
import Route from './Route';

const IndexRoute = () => {
  const { user } = useAuth();

  if (user) {
    handleHubSelection.intializeHubSelection();
    const isHubMember =
      user?.hubData?.active && user?.hubData?.hubAccountsData?.length > 1;

    if (isHubMember && handleHubSelection.shouldHubSelect()) {
      return <Redirect to="/accounts" />;
    }
    return (
      <Redirect to={hasFeatureFlag('isWorkflowBuilder', user) ? '/home' : '/dashboard'} />
    );
  }

  return <Redirect to="/signin" />;
};

const Routes = () => {
  const { user } = useAuth();
  const { i18nSetAntFormValidateMessages, i18nSetAntLocale } = useI18nFormatters();

  const formValidateMessages = i18nSetAntFormValidateMessages();
  const locale = i18nSetAntLocale();

  return (
    <ConfigProvider form={{ validateMessages: formValidateMessages }} locale={locale}>
      <Switch>
        {/* Public routes */}
        <Route path="/signin" component={Login} isPrivate={false} />
        <Route path="/" exact component={IndexRoute} isPrivate={false} />
        {/* Private routes */}
        <Route
          path="/dashboard"
          exact
          component={Dashboard}
          requiredPermissions={['metrics:read']}
          onlyWithoutFlag="isWorkflowBuilder"
        />

        <Route path="/accounts" exact component={Accounts} />

        <Route path="/home" exact component={Home} onlyWithFlag="isWorkflowBuilder" />

        <Route
          path="/person"
          exact
          component={Executions}
          requiredPermissions={['executions:read']}
          onlyWithFlag="isWorkflowBuilder"
        />
        <Route
          path="/executions"
          exact
          component={Executions}
          requiredPermissions={['executions:read']}
          onlyWithoutFlag="isWorkflowBuilder"
        />

        <Route
          path="/person/:executionId"
          exact
          component={ExecutionDetails}
          requiredPermissions={['executions:read']}
          onlyWithFlag="isWorkflowBuilder"
        />
        <Route
          path="/executions/:executionId"
          exact
          component={ExecutionDetails}
          requiredPermissions={['executions:read']}
          onlyWithoutFlag="isWorkflowBuilder"
        />

        <Route path="/company" exact component={CompanySearching} />
        <Route path="/company/:transactionId" exact component={KYBCompanyDetails} />

        <Route
          path="/profile/people"
          exact
          component={
            hasFeatureFlag('isWorkflowBuilder', user) ? GlobalProfiles : Profiles
          }
          requiredPermissions={['people:read']}
          onlyWithFlag="profilesModule"
        />
        <Route
          path="/profile/people/:personId"
          component={
            hasFeatureFlag('isWorkflowBuilder', user)
              ? GlobalPeopleDetails
              : PeopleDetails
          }
          requiredPermissions={['people:read:details']}
          onlyWithFlag="profilesModule"
        />
        <Route
          path="/profile/companies"
          exact
          component={Profiles}
          requiredPermissions={['companies:read']}
        />
        <Route
          path="/profile/companies/:companyId"
          component={CompanyDetails}
          requiredPermissions={['companies:read:details']}
        />
        <Route
          path="/liveness"
          exact
          component={Liveness}
          requiredPermissions={['faceliveness:read']}
        />
        <Route
          path="/liveness-attempts"
          exact
          component={Liveness}
          requiredPermissions={['faceliveness:read']}
        />
        <Route
          path="/liveness-whitelist"
          exact
          component={Liveness}
          requiredPermissions={['faceliveness:read']}
        />
        <Route
          path="/face-authenticator"
          exact
          component={FaceAuthenticator}
          requiredPermissions={['face-authenticator:read']}
        />
        <Route
          path="/restrictions"
          exact
          component={Restrictions}
          requiredPermissions={['restrictive-list:read']}
        />
        <Route
          path="/blocked-documents"
          exact
          component={BlockedDocuments}
          customer="SKY"
        />
        <Route path="/files/:fileId" component={Files} />
        <Route
          path="/onboarding-links"
          exact
          component={OnboardingLinksList}
          requiredPermissions={['onboardings:read']}
        />
        <Route
          path="/onboarding-statistics"
          exact
          component={OnboardingStatistics}
          requiredPermissions={['onboardings:read']}
        />
        <Route
          path="/activities"
          exact
          component={Activities}
          or
          requiredPermissions={['onboardings:read', 'profile-templates:read']}
        />
        <Route
          path="/profile-templates/profile-models"
          exact
          component={ProfileModelsList}
          requiredPermissions={['profile-templates:read']}
          onlyWithoutFlag="isWorkflowBuilder"
        />
        <Route
          path="/workflows"
          exact
          component={Workflows}
          requiredPermissions={['profile-templates:read']}
          onlyWithFlag="isWorkflowBuilder"
        />
        <Route
          path="/profile-templates/profile-models/registration"
          exact
          component={ProfileModelCreate}
          requiredPermissions={['profile-templates:create']}
          onlyWithoutFlag="isWorkflowBuilder"
        />
        <Route
          path="/profile-templates/profile-models/:profileTemplateId"
          exact
          component={ProfileModelUpdate}
          or
          requiredPermissions={['profile-templates:update', 'profile-templates:read']}
          onlyWithoutFlag="isWorkflowBuilder"
        />
        <Route
          path="/tags-and-reasons"
          exact
          component={TagsAndReasons}
          or
          // requiredPermissions={['tags-and-reasons:read']}
        />
        <Route
          path="/notifications"
          exact
          component={NotificationCenter}
          or
          // requiredPermissions={[':read']}
        />
        <Route
          path="/routines"
          exact
          component={RoutineList}
          requiredPermissions={['routines:read']}
          onlyWithoutFlag="isWorkflowBuilder"
        />
        <Route
          path="/routines/new"
          exact
          component={RoutineDetails}
          requiredPermissions={['routines:create']}
          onlyWithoutFlag="isWorkflowBuilder"
        />
        <Route
          path="/routines/:routineId"
          exact
          component={RoutineDetails}
          requiredPermissions={['routines:update']}
          onlyWithoutFlag="isWorkflowBuilder"
        />

        {pdvTenants?.includes(user?.tenantId) && (
          <Route
            path="/users-pdv"
            exact
            component={UsersPDV}
            product="management"
            requiredPermissions={['users-pdv:read']}
          />
        )}

        <Route
          path="/users"
          exact
          component={UsersAndGroupsTab}
          product="management"
          requiredPermissions={['users:read']}
          or
        />

        <Route
          path="/groups"
          exact
          component={UsersAndGroupsTab}
          product="management"
          requiredPermissions={['users:read']}
        />

        <Route
          path="/groups/create"
          exact
          component={GroupsCreate}
          product="management"
          requiredPermissions={['users:create']}
        />
        <Route
          path="/groups/:id/edit"
          exact
          component={GroupsCreate}
          product="management"
          requiredPermissions={['users:update']}
        />

        <Route
          path="/groups/:id"
          exact
          component={GroupsDetails}
          product="management"
          requiredPermissions={['users:read']}
        />
        <Route
          path="/users/create"
          exact
          component={UsersCreate}
          product="management"
          requiredPermissions={['users:create']}
        />
        <Route
          path="/consumption"
          exact
          component={Consumptions}
          product="management"
          requiredPermissions={['consumption:read']}
        />
        <Route
          path="/consumption/:id"
          exact
          component={ConsumptionDetails}
          product="management"
          requiredPermissions={['consumption:read']}
        />
        <Route
          path="/faces-base/private-faceset"
          exact
          component={Faces}
          product="management"
          requiredPermissions={['private-faceset:read']}
        />
        <Route
          path="/faces-base/mobile-faces"
          exact
          component={Faces}
          product="management"
          requiredPermissions={['mobile-faces:read']}
        />
        <Route path="/onboarding-builder" exact component={OnboardingBuilder} />
        <Route
          path="/registration-info"
          exact
          component={RegistrationInfo}
          product="management"
          requiredPermissions={['users:read']}
        />
        <Route
          path="/access-keys"
          exact
          component={AccessKeys}
          product="management"
          requiredPermissions={['access-keys:read']}
        />
        <Route
          path="/integration-guide"
          exact
          component={AccessKeys}
          product="trust"
          requiredPermissions={['integration-guide:read']}
        />
        <Route
          path="/security-and-privacy/audit-log"
          exact
          component={AuditLog}
          requiredPermissions={['audit-log:read']}
        />
        <Route
          path="/security-and-privacy/security-configuration"
          exact
          component={SecurityConfiguration}
          requiredPermissions={[
            'security-configuration:read',
            'security-configuration:update',
          ]}
        />
        <Route
          path="/security-and-privacy/liveness-configuration"
          exact
          component={LivenessConfiguration}
          requiredPermissions={[
            'liveness-configuration:read',
            'liveness-configuration:update',
          ]}
        />
        <Route path="/unauthorized" exact component={Unauthorized} />
      </Switch>
    </ConfigProvider>
  );
};

export default Routes;
